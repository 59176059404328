body {
  background-color: #282c34;
}

pre {
  text-align: left;
  color: white;
  line-height: initial;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

input {
  min-width: 30%;
  text-align: center;
}

.container-fluid {
  min-height: 100vh;
}